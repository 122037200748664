import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [

  {
    path: '',
    loadChildren: () => import('./page/home/home.module').then( m => m.HomePageModule),
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./page/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'monitor',
    loadChildren: () => import('./page/monitor/monitor.module').then( m => m.MonitorPageModule)
  },
  {
    path: 'fahrten',
    loadChildren: () => import('./page/trips/trips.module').then( m => m.TripsPageModule)
  },
  {
    path: 'fahrten/details/:index',
    loadChildren: () => import('./page/detail/detail.module').then( m => m.DetailPageModule)
  },
  {
    path: 'error',
    loadChildren: () => import('./page/error/error.module').then( m => m.ErrorPageModule)
  },
  {
    path: '**',
    redirectTo: ''
  },
  {
    path: 'monitor',
    loadChildren: () => import('./page/monitor/monitor.module').then( m => m.MonitorPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
